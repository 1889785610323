<template>
  <Overlay
    v-if="overlayOpen"
    :show-arrow-back="overlayShowArrowBack && !flaggedReset"
    :hide-close-button="flaggedReset"
    width="395"
    :title="overlayTitle"
    @goBack="onGoBack"
    @close="TOGGLE_MY_ACCOUNT_STATUS"
  >
    <div
      slot="content"
      class="my-account-layout in-overlay"
    >
      <div class="my-account-content">
        <MyAccount
          v-if="currentView==='MyAccount'"
          :is-clinician="true"
          @viewUpdated="navigate"
        />
        <ChangePassword
          v-if="currentView==='changePassword'"
          :is-clinician="true"
          @finished="onGoBack"
        />
        <EditUsername
          v-if="currentView==='EditUsername'"
          :is-clinician="true"
          @overlayGoBack="onGoBack"
        />
        <ContactInfo
          v-if="currentView==='EditContanctInfo'"
          :is-clinician="true"
        />
      </div>
    </div>
  </Overlay>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Overlay from '@/components/clinician/Overlay'
import MyAccount from '@/components/user/MyAccount'
import ChangePassword from '@/components/security/ChangePassword'
import EditUsername from '@/components/user/EditUsername'
import ContactInfo from '@/components/user/ContactInfo'
import { STATUS_PASSWORD_RESET } from '@/data/userStatusValues'

export default {
  name: 'MyAccountClinician',
  components: {
    Overlay,
    MyAccount,
    ChangePassword,
    EditUsername,
    ContactInfo
  },
  data () {
    return {
      flaggedReset: Number(this.$store.getters.loggedInUser.status) === Number(STATUS_PASSWORD_RESET),
      currentView: ''
    }
  },
  computed: {
    overlayTitle () {
      switch (this.currentView) {
        case 'MyAccount':
          return 'My Account'
        case 'changePassword':
          return 'Change password'
        case 'EditUsername':
          return 'Edit Username'
        case 'EditContanctInfo':
          return 'Edit Contact Info'
        default:
          return ''
      }
    },
    overlayShowArrowBack () {
      switch (this.currentView) {
        case 'MyAccount':
          return false
        case 'EditContanctInfo':
        case 'changePassword':
        case 'EditUsername':
          return true
        default:
          return false
      }
    },
    ...mapGetters({
      overlayOpen: 'getMyAccountIsOpen'
    })
  },
  created () {
    this.currentView = this.getCurrentValue()
  },
  methods: {
    ...mapActions([
      'TOGGLE_MY_ACCOUNT_STATUS'
    ]),
    onGoBack () {
      this.currentView = this.getCurrentValue()
    },
    navigate (to) {
      this.currentView = this.getCurrentValue(to)
    },
    getCurrentValue (to) {
      const value = !to ? 'MyAccount' : to
      return this.flaggedReset ? 'changePassword' : value
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
