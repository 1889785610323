<template>
  <div class="my-account">
    <div class="my-account-header">
      <initials-circle
        :first="firstInitial"
        :second="secondInitial"
      />
      <h2 class="text-capitalize">
        {{ user.firstName }} {{ user.lastName }}
      </h2>
      <span
        v-if="isClinician && accountData.redox_id"
        class="provider-id"
      >{{ $t("providerID") }}#: {{ accountData.redox_id }}</span>
    </div>
    <div class="my-account-content">
      <div class="user-password">
        <p>{{ $t("usernameAndpassword") }}</p>
        <div class="user">
          <span>{{ user.username }}</span>
          <router-link
            v-if="!isClinician"
            :to="{ name: 'EditUsername' }"
            active-class="active"
            exact
          >
            {{ $t("edit") }}
          </router-link>
          <a
            v-if="isClinician"
            @click="$emit('viewUpdated', 'EditUsername')"
          >
            {{ $t("edit") }}
          </a>
        </div>
        <div class="password">
          <truncated-text :text="lastPasswordChange" />
          <router-link
            v-if="!isClinician"
            :to="{ name: 'ChangePassword' }"
            active-class="active"
            exact
          >
            {{ $t("changePassword") }}
          </router-link>
          <a
            v-if="isClinician"
            @click="$emit('viewUpdated', 'changePassword')"
          >
            {{ $t("changePassword") }}
          </a>
        </div>
      </div>
      <div
        v-if="accountData.contact_primary && (accountData.contact_primary.phone || accountData.contact_primary.email)"
        class="contact-info"
      >
        <p>{{ $t("contactInfro") }} <span>{{ $t("notifications") }}</span></p>
        <p class="small">
          {{ $t("contactInfoNotificationMessage") }}
        </p>
        <div
          v-if="accountData.contact_primary && accountData.contact_primary.phone"
          class="phone"
        >
          <span>{{ $maskedPhone(accountData.contact_primary.phone.value) }}</span>
          <c-switch
            v-if="settings.sms_enabled"
            type="default"
            variant="success"
            :pill="true"
            :checked="accountData.contact_primary.phone.enabled == 1"
            @change="togglePhoneEnabled"
          />
        </div>
        <div
          v-if="accountData.contact_primary && accountData.contact_primary.email"
          class="email"
        >
          <truncated-text :text="accountData.contact_primary.email.value" />
          <c-switch
            type="default"
            variant="success"
            :pill="true"
            :checked="accountData.contact_primary.email.enabled == 1"
            @change="toggleEmailEnabled"
          />
        </div>
      </div>
      <div
        v-if="accountData.contact_primary && accountData.contact_primary.email && accountData.contact_primary.email.enabled == 0 && ((accountData.contact_primary.phone && accountData.contact_primary.phone.enabled == 0) || !settings.sms_enabled)"
        class="alert alert-warning"
        role="alert"
      >
        {{ $t('notificationsDisabledByUser') }}
      </div>
      <div
        v-if="!settings.sms_enabled"
        class="alert alert-warning"
        role="alert"
      >
        {{ $t('notificationsDisabledByAccount') }}
      </div>
      <router-link
        v-if="!isClinician"
        :to="{ name: 'ContactInfo' }"
        class="add-email-phone"
        active-class="active"
        exact
      >
        <span v-if="accountData.contact_secondary && (accountData.contact_secondary.email || accountData.contact_secondary.phone)">
          {{ $t(editContact) }}
        </span>
        <span v-else>{{ $t(addContact) }}</span>
      </router-link>
      <a
        v-if="isClinician"
        href="javascript:void(0)"
        class="add-email-phone"
        @click="$emit('viewUpdated', 'EditContanctInfo')"
      >
        <span v-if="accountData.contact_secondary && (accountData.contact_secondary.email || accountData.contact_secondary.phone)">
          {{ $t(editContact) }}
        </span>
        <span v-else>{{ $t(addContact) }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import cSwitch from '@/components/common/Switch'
import TruncatedText from '@/components/common/TruncatedText'
import moment from 'moment'
import InitialsCircle from '@/components/common/InitialsCircle'
import { Helpers } from '@/mixins/Helpers'
import { DateTimeHelper } from '@/mixins/DateTimeHelper'
import { mapGetters } from 'vuex'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'MyAccount',
  components: {
    InitialsCircle,
    TruncatedText,
    cSwitch
  },
  mixins: [Helpers, DateTimeHelper],
  props: ['isClinician'],
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    editContact () {
      return `edit${this.settings.sms_enabled ? 'PhoneOr' : ''}Email`
    },
    addContact () {
      return `add${this.settings.sms_enabled ? 'PhoneOr' : ''}Email`
    },
    ...mapGetters({
      accountData: 'myAccountData',
      user: 'loggedInUser',
      settings: 'generalSettings'
    }),
    lastPasswordChange () {
      const valid = moment(this.accountData.last_password_change).isValid()
      const lastChangedDate = valid ? DateTimeHelper.methods.$toLocal(this.accountData.last_password_change, 'MMMM DD, YYYY') : '-'
      return `Last updated ${lastChangedDate}`
    }
  },
  created () {
    const loader = this.$loading.show()
    this.$store.dispatch('MY_ACCOUNT', { id: this.user.id }).finally(e => loader.hide())
  },
  methods: {
    togglePhoneEnabled (value) {
      this.toggleEnabled('phone', value)
    },
    toggleEmailEnabled (value) {
      this.toggleEnabled('email', value)
    },
    toggleEnabled (type, value) {
      const loader = this.$loading.show()
      const data = {
        user_id: this.user.id,
        enabled: (value ? 1 : 0),
        id: this.accountData.contact_primary[type].id,
        channel: type === 'email' ? 1 : 2
      }
      this.$store.dispatch('CONTACT_INFO_UPDATE', data).finally(() => {
        this.$store.dispatch('MY_ACCOUNT', { id: this.user.id })
        componentRefreshSingleton.refreshComponentViewByName('SettingsClinicalUsers')
        loader.hide()
      })
    }
  }
}
</script>
<style lang="scss">
.my-account{
  #outer-circle {
    height: 70px;
    width: 70px;
    margin: 0;
    #inner-circle {
      height: 66px;
      width: 66px;
      border-width: 2px;
      margin: -33px 0px 0px -33px;
      span {
        font-size: 28px;
      }
      .first-letter {
        width: 16px;
        height: 33px;
        top: 8px;
        left: 12px;
      }
      .second-letter {
        width: 16px;
        height: 33px;
        bottom: 8px;
        right: 12px;
      }
    }
  }
}
</style>
