<template>
  <div
    class="modal fade show owl-modal"
    tabindex="-1"
    role="dialog"
  >
    <div
      class="modal-dialog"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="$emit('close')"
          >
            <span
              v-if="!exitIsText"
              aria-hidden="true"
            >&times;</span>
            <span
              v-if="exitIsText"
              class="exit-text"
              aria-hidden="true"
            >Exit</span>
          </button>
        </div>
        <div class="modal-body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    exitIsText: {
      type: Boolean,
      default: false
    }
  }
}
</script>
