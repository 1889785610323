<template>
  <span
    ref="textContainer"
    v-tooltip="tooltipData"
    class="truncated-text-container"
    :class="{ 'truncated': truncated, 'whith-caret': caret, overflows: overflows }"
    @click="toggleShow"
    @mouseleave="tooltipData.show = false"
  >
    <i
      v-if="caret && overflows"
      ref="caretRef"
      :class="`fas fa-caret-${truncated ? 'down' : 'right'}`"
    />
    <span
      ref="text"
      class="truncated-text"
      :class="{'with-tooltip': showTooltip}"
      v-html="truncatedText()"
    />
  </span>
</template>
<script>
export default {
  name: 'TruncatedText',
  props: {
    caret: {
      type: Boolean,
      default: false
    },
    defaultOpen: {
      type: Boolean,
      default: false
    },
    text: {
      default: '',
      type: String
    },
    showTooltip: {
      type: Boolean,
      default: false
    },
    textLimit: {
      type: Number,
      default: 0
    }
  },
  data: function () {
    return {
      truncated: false,
      overflows: false,
      tooltipData: {
        content: this.text,
        classes: ['supervisor-tooltip'],
        trigger: 'manual',
        placement: 'bottom',
        show: false
      },
      overflown: false
    }
  },
  computed: {
    elementCantContain () {
      if (this.textLimit) {
        return this.textLimit
      }
      const fz = Number(window.getComputedStyle(this.$refs.text, null).getPropertyValue('font-size').replace('px', ''))
      const elWidth = this.getContainerWidth()
      let extraChar = 0
      if (this.text.indexOf(' ') === -1) {
        extraChar = 1.5
      }
      return Math.floor(elWidth / ((fz / 2) + extraChar))
    }
  },
  watch: {
    text () {
      this.calcOverflow()
    }
  },
  created () {
    this.truncated = this.defaultOpen
    this.$nextTick(() => {
      this.calcOverflow()
    })
  },
  methods: {
    truncatedText () {
      let text = this.text
      if (!this.overflown && this.$refs.text && text.indexOf(' ') === -1) {
        const separator = this.showTooltip ? '' : ' '
        text = [text.slice(0, this.elementCantContain), separator, text.slice(this.elementCantContain)].join('')
      }
      return this.overflown ? `${this.trimmed(text)}...` : text
    },
    trimmed (text) {
      return text.substring(0, this.elementCantContain)
    },
    calcOverflow () {
      this.overflows = this.text && this.text.length > this.elementCantContain
      this.overflown = this.overflows && !this.truncated
    },
    toggleShow () {
      if (this.showTooltip) {
        this.tooltipData.show = !this.tooltipData.show
        this.tooltipData.content = this.text
      } else if (this.overflows) {
        this.truncated = !this.truncated
        this.calcOverflow()
      }
    },
    getContainerWidth () {
      const pl = Number(window.getComputedStyle(this.$refs.textContainer, null).getPropertyValue('padding-left').replace('px', ''))
      const pr = Number(window.getComputedStyle(this.$refs.textContainer, null).getPropertyValue('padding-right').replace('px', ''))
      const caretWidth = this.$refs.caretRef ? this.$refs.caretRef.clientWidth : 0

      return this.$refs.textContainer.clientWidth - pl - pr - caretWidth
    }
  }
}
</script>
<style lang="scss" scoped>
  .truncated-text {
    display: inline-block;
    max-width: 100%;
  }
  .truncated-text-container{
    display: inline-block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    &.overflows {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .whith-caret{
    i{
      padding-right: 10px;
    }
    display: flex;
    .truncated-text{
      max-width: calc(100% - 20px);
    }
  }
  .with-tooltip {
    &:hover {
      text-decoration: underline;
    }
  }
  .truncated-text-container.truncated{
    white-space: normal !important;
    overflow: visible !important;
  }
</style>
