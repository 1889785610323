<template>
  <div
    class="contact-info-container"
    :class="{ inClinician: isClinician }"
  >
    <div
      v-if="!showLoader && !showModal"
      class="contact-info"
    >
      <p
        v-if="accountData.contact_primary && accountData.contact_primary.email"
        class="text-capitalize"
      >
        {{ $tc('email', 2) }}
      </p>
      <div class="email-list">
        <div
          v-for="(email, index) in orderedEmails"
          :key="index"
        >
          <div class="email">
            <div class="prepend">
              <span
                class="primary"
                :class="{disabled: !email.id}"
                @click.prevent="togglePrimary('email')"
              >
                <i
                  class="fa-circle"
                  :class="{ fa: email.primary == 1, far: !email.primary || email.primary == 0}"
                />
                <span>{{ $t("primary") }}</span>
              </span>
              <div
                v-if="email.editable === '1'"
                class="buttons"
              >
                <a
                  v-if="!editing.email"
                  href=""
                  @click.prevent="enableEdition('email', email.value)"
                >{{ $t("edit") }}</a>
                <a
                  v-if="editing.email"
                  href=""
                  class="cancel"
                  @click.prevent="cancel('email')"
                >{{ $t("cancel") }}</a>
              </div>
            </div>
            <div
              v-if="!email.editable || email.editable == '0' || (email.editable == '1' && !editing.email)"
              class="value"
            >
              <truncated-text :text="email.value" />
            </div>
            <form
              v-if="editing.email && email.editable == '1'"
              @submit.prevent="saveSecondary('email')"
            >
              <input
                id="email"
                ref="email"
                v-model.trim="email_secondary"
                v-validate="'required|email'"
                type="text"
                name="email"
                data-vv-as="Email"
              >
              <span
                v-show="errors.has('email')"
                class="error"
              >{{ errors.first('email') }}</span>
            </form>
          </div>
        </div>
        <div class="add-new">
          <a
            v-if="editing.email"
            href=""
            @click.prevent="saveSecondary('email')"
          >{{ $t("done") }}</a>
          <a
            v-if="!editing.email && canAddnewByChannel('email')"
            href=""
            @click.prevent="addNew('email')"
          >{{ $t("addEmailAdress") }}</a>
        </div>
      </div>
    </div>
    <div
      v-if="!showLoader && settings.sms_enabled && !showModal"
      class="contact-info"
    >
      <p
        v-if="(accountData.contact_primary && accountData.contact_primary.phone) || editing.phone"
        class="text-capitalize"
      >
        {{ $tc('phone', 2) }}
      </p>
      <div class="phone-list">
        <div
          v-for="(phone, index) in orderedPhones"
          :key="index"
        >
          <div class="email">
            <div class="prepend">
              <span
                class="primary"
                :class="{disabled: !phone.id}"
                @click.prevent="togglePrimary('phone')"
              >
                <i
                  class="fa-circle"
                  :class="{ fa: phone.primary == 1, far: !phone.primary || phone.primary == 0}"
                />
                <span class="primary">{{ $t("primary") }}</span>
              </span>
              <div
                v-if="phone.editable === '1'"
                class="buttons"
              >
                <a
                  v-if="!editing.phone"
                  href=""
                  @click.prevent="enableEdition('phone', phone.value)"
                >{{ $t("edit") }}</a>
                <a
                  v-if="editing.phone"
                  href=""
                  class="cancel"
                  @click.prevent="cancel('phone')"
                >{{ $t("cancel") }}</a>
              </div>
            </div>
            <div
              v-if="!phone.editable || phone.editable == '0' || (phone.editable == '1' && !editing.phone)"
              class="value"
            >
              <p>{{ $maskedPhone(phone.value) }}</p>
            </div>
            <form
              v-if="editing.phone && phone.editable == '1'"
              @submit.prevent="saveSecondary('phone')"
            >
              <masked-input
                v-model="phone_secondary"
                type="tel"
                autocomplete="off"
                class="form-control"
                :mask="['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]"
                :guide="true"
                :show-mask="true"
                placeholder-char="_"
                @input="cleanPhone"
              />
              <input
                id="phone"
                ref="phone"
                v-model="phone_secondaryClean"
                v-validate="'required|min:10|max:10'"
                type="hidden"
                name="phone"
                data-vv-validate-on="blur"
                data-vv-as="Phone"
              >
              <span
                v-show="errors.has('phone')"
                class="error"
              >{{ errors.first('phone') }}</span>
            </form>
          </div>
        </div>
      </div>
      <div class="add-new">
        <a
          v-if="editing.phone"
          href=""
          @click.prevent="saveSecondary('phone')"
        >{{ $t("done") }}</a>
        <a
          v-if="!editing.phone && canAddnewByChannel('phone')"
          href=""
          @click.prevent="addNew('phone')"
        >{{ $t("addPhoneAdress") }}</a>
      </div>
    </div>
    <div
      v-if="showLoader"
      class="loader"
    >
      <p>An Access Code has been sent <br> to:  {{ validateValue }}</p>
    </div>
    <modal
      v-if="showModal && !isClinician"
      :exit-is-text="true"
      @close="closeModal"
    >
      <validate-code
        slot="body"
        mode="validate-contact-info"
        :channel="validateChannel"
        :value="validateValue"
        :uuid="uuid"
        @resend="getAccessCode"
        @submit="onValidateCode"
      />
    </modal>
    <div
      v-if="showModal && isClinician && !showLoader"
      class="validate-code-wrapper"
    >
      <validate-code
        slot="body"
        mode="validate-contact-info"
        :channel="validateChannel"
        :value="validateValue"
        :uuid="uuid"
        @resend="getAccessCode"
        @submit="onValidateCode"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { ErrorsDictionary } from '@/mixins/ErrorsDictionary'
import Modal from '@/components/common/Modal'
import ValidateCode from '@/components/security/ValidateCode'
import { Helpers } from '@/mixins/Helpers'
import MaskedInput from 'vue-text-mask'
import TruncatedText from '@/components/common/TruncatedText'
import { componentRefreshSingleton } from '@/mixins/ComponentRefresh/ComponentRefreshSingleton'

export default {
  name: 'ContactInfo',
  components: {
    Modal,
    MaskedInput,
    ValidateCode,
    TruncatedText
  },
  mixins: [ErrorsDictionary, Helpers],
  props: ['isClinician'],
  data () {
    return {
      email_secondary: '',
      phone_secondary: '',
      phone_secondaryClean: '',
      editingType: '',
      showModal: false,
      showLoader: false,
      editing: {
        email: false,
        phone: false
      }
    }
  },
  computed: {
    validateChannel () {
      return this.editingType === 'email' ? 1 : 2
    },
    validateValue () {
      return this.editingType === 'email' ? this.email_secondary : this.$maskedPhone(this.phone_secondaryClean)
    },
    orderedEmails: function () {
      const emails = []
      if (this.accountData.contact_primary && this.accountData.contact_primary.email !== null) {
        emails.push(this.accountData.contact_primary.email)
      }
      return emails
    },
    orderedPhones: function () {
      const phones = []
      if (this.accountData.contact_primary && this.accountData.contact_primary.phone !== null) {
        phones.push(this.accountData.contact_primary.phone)
      }
      return phones
    },
    ...mapGetters({
      accountData: 'myAccountData',
      user: 'loggedInUser',
      settings: 'generalSettings',
      uuid: 'getAccessCodeUUID'
    })
  },
  created () {
    this.$store.dispatch('MY_ACCOUNT', { id: this.user.id })
  },
  mounted () {
    this.$validator.localize('en', this.errorsDictionary)
  },
  methods: {
    cleanPhone (phone) {
      this.phone_secondaryClean = phone.replace(/\D/g, '')
    },
    getEditable (fieldType) {
      let editable = {}
      this.accountData.contact.forEach((contact) => {
        if (contact.channel === fieldType && contact.editable === '1') {
          editable = contact
        }
      })
      return editable
    },
    onValidateCode () {
      const data = { user_id: this.user.id, enabled: false }
      const message = this.$t(`${this.editingType}Verified`)
      let editable = {}
      switch (this.editingType) {
        case 'email':
          editable = this.getEditable(1)
          data.id = editable.id
          data.channel = 1
          data.value = this.email_secondary
          break
        case 'phone':
          editable = this.getEditable(2)
          data.id = editable.id
          data.channel = 2
          data.value = this.phone_secondaryClean
          break
      }
      this.dispatchContactInfo(data, this.editingType, data.id ? 'CONTACT_INFO_UPDATE' : 'CONTACT_INFO_CREATE').then(() => {
        this.$toast.success({ message: message })
      })
    },
    enableEdition (type, value) {
      this[`${type}_secondary`] = value
      this.editing[type] = true
      this.focusByType(type)
    },
    addNew (type) {
      const arrayName = this.getOrderedArrayName(type)
      this[arrayName].push({ editable: '1' })
      // set editing true for element
      this.editing[type] = true
      // focus input field
      this.focusByType(type)
    },
    cancel (type) {
      this[`${type}_secondary`] = ''
      this.editing[type] = false
      if (type === 'phone') {
        this.phone_secondaryClean = ''
      }
      this.deleteIfNoValue(type)
    },
    getOrderedArrayName (type) {
      return `ordered${this.capitalizeString(type)}s`
    },
    deleteIfNoValue (type) {
      const arrayName = this.getOrderedArrayName(type)
      for (let i = 0; i < this[arrayName].length; i++) {
        const field = this[arrayName][i]
        if (!field.value) {
          this[arrayName].splice(i, 1)
        }
      }
    },
    closeModal () {
      this.showModal = false
      this.focusByType(this.editingType)
    },
    focusByType (type) {
      Vue.nextTick(() => {
        const el = document.getElementById(type)
        if (el.length) {
          if (typeof el.selectionStart === 'number') {
            const value = el.id === 'phone' ? el.value.replace(/\D/g, '') : el.value
            el.selectionStart = el.selectionEnd = value.length
          } else if (typeof el.createTextRange !== 'undefined') {
            const range = el.createTextRange()
            range.collapse(false)
            range.select()
          }
          el.focus()
        }
      })
    },
    togglePrimary (type) {
      const data = { user_id: this.user.id, primary: true }
      switch (type) {
        case 'email':
          data.id = this.accountData.contact_secondary.email ? this.accountData.contact_secondary.email.id : null
          data.channel = 1
          break
        case 'phone':
          data.id = this.accountData.contact_secondary.phone ? this.accountData.contact_secondary.phone.id : null
          data.channel = 2
          break
      }
      if (data.id) {
        const loader = this.$loading.show()
        this.$store.dispatch('MY_ACCOUNT_TOGGLE_PRYMARY', { type, data }).then(() => {
          this.$store.dispatch('MY_ACCOUNT', { id: this.user.id })
        }).finally(() => {
          componentRefreshSingleton.refreshComponentViewByName('SettingsClinicalUsers')
          loader.hide()
        })
      }
    },
    getAccessCode (isSendCode) {
      this.showLoader = true
      const data = {
        channel: this.editingType === 'email' ? 1 : 2,
        value: this.editingType === 'email' ? this.email_secondary : this.phone_secondaryClean,
        username: this.accountData.username,
        contact_info: 1
      }
      this.$store.dispatch('SECURE_GET_ACCESS_CODE', data)
        .then(response => {
          if (response && response.status !== 200) {
            const haveError = response.data && response.data.errors
            const haveErrorsCount = haveError && response.data.errors.error && response.data.errors.error.length
            const errorValue = haveErrorsCount ? response.data.errors.error[0] : response
            return this.$handleApiError(errorValue)
          }
          isSendCode ? this.showModal = true : this.$toast.success({ message: `An access code has been resend to ${this.validateValue}` })
        })
        .finally(() => {
          this.showLoader = false
        })
    },
    saveSecondary (type) {
      this.$validator.validate(type).then((valid) => {
        if (valid) {
          this.editingType = type
          this.getAccessCode(true)
        }
      })
    },
    dispatchContactInfo (data, type, actionName) {
      return this.$store.dispatch(actionName, data).finally(() => {
        this.editing[type] = false
        this.showModal = false
        this.$store.dispatch('MY_ACCOUNT', { id: this.user.id })
        componentRefreshSingleton.refreshComponentViewByName('SettingsClinicalUsers')
      })
    },
    canAddnewByChannel (type) {
      let r = true
      const channel = type === 'email' ? 1 : 2
      if (this.accountData.contact) {
        for (let i = 0; i < this.accountData.contact.length; i++) {
          const contact = this.accountData.contact[i]
          if (contact.channel === channel && contact.editable === '1' && contact.value.length) {
            r = false
          }
        }
      }
      return r
    }
  }
}
</script>
<style lang="scss" scoped>
  .inClinician {
    .validate-code-wrapper {
      margin-top: 20px;
    }
    .loader {
      background: none;
    }
  }
</style>
