import { render, staticRenderFns } from "./MyAccountClinician.vue?vue&type=template&id=716f389b&scoped=true&"
import script from "./MyAccountClinician.vue?vue&type=script&lang=js&"
export * from "./MyAccountClinician.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "716f389b",
  null
  
)

export default component.exports