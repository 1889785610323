<template>
  <nav
    id="clinician-nav-bar"
    class="navbar"
  >
    <div
      class="left-container"
      :class="{'no-sidebar': noSidebar}"
    >
      <button
        v-if="!noSidebar"
        class="navbar-toggler"
        type="button"
        @click="TOGGLE_SIDEBAR_STATUS"
      >
        <span class="navbar-toggler-icon" />
      </button>
      <router-link
        :to="{name: 'Home'}"
        class=""
      >
        <img
          class="logo-img"
          src="@/assets/images/owl-logo-white.svg"
          alt="Owl Logo"
          height="30"
        >
      </router-link>
    </div>
    <div
      ref="navBarMenu"
      class="initials-container"
      @mouseover="toggleDropdown"
      @mouseout="mouseOut"
      @click="toggleDropdown"
    >
      <span>{{ user.firstName }} {{ secondInitial }}</span>
      <div
        v-if="dropdownOpen"
        class="dropdown-content"
        @mouseleave="dropdownOpen = false"
      >
        <a
          href=""
          @click.prevent="toggleMyAccount"
        >{{ $t("sideBar.myAccount") }}</a>
        <a
          href=""
          @click.prevent="signOut"
        >{{ $t("sideBar.signOut") }}</a>
      </div>
      <initials-circle
        :first="firstInitial"
        :second="secondInitial"
        first-id="navbar-outer-circle"
        second-id="navbar-inner-circle"
      />
    </div>
  </nav>
</template>
<script>
import { mapActions } from 'vuex'
import InitialsCircle from '@/components/common/InitialsCircle'
import { Helpers } from '@/mixins/Helpers'
export default {
  name: 'ClinicianNavbar',
  components: {
    InitialsCircle
  },
  mixins: [Helpers],
  props: ['user', 'noSidebar'],
  data () {
    return {
      dropdownOpen: false,
      openedByClick: false
    }
  },
  created () {
    document.addEventListener('click', this.handleClickOutside)
  },
  methods: {
    ...mapActions([
      'TOGGLE_SIDEBAR_STATUS'
    ]),
    signOut () {
      this.$store.dispatch('SIGN_OUT') // Sign out server side
      this.$nextTick().then(() => {
        this.$store.dispatch('SIGN_OUT_CLIENT_SIDE')
        this.$router.push({ name: 'Login' })
      })
    },
    mouseOut (event) {
      if ((!event.target || !event.relatedTarget) || (event.target.className !== 'initials-container' && event.relatedTarget.className === 'navbar')) {
        this.dropdownOpen = false
      }
    },
    toggleMyAccount () {
      this.$store.dispatch('TOGGLE_MY_ACCOUNT_STATUS')
    },
    toggleDropdown (event) {
      if (event.type === 'click') {
        this.dropdownOpen = this.openedByClick = !this.openedByClick
        return
      }

      if ((!this.$isMobile() && event.type === 'mouseover')) {
        this.dropdownOpen = true
      }

      if ((!this.$isMobile() && event.type === 'mouseout') && !this.openedByClick) {
        this.dropdownOpen = false
      }
    },
    handleClickOutside (event) {
      const navBarMenu = this.$refs.navBarMenu
      const target = event.target

      if (navBarMenu && navBarMenu !== target && !navBarMenu.contains(target)) {
        this.dropdownOpen = this.openedByClick = false
      }
    }
  }
}
</script>
